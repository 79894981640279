<!--全景数据转换-->
<template>
  <div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
        showWriteComment: false,
        sending: false
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    watch: {
      pano(val) {
        this.updatePano(val)
      }
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
        }
      })
      this.updatePano()
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      updatePano(pano) {
        var p = pano || this.pano
        this.getFunc('changePublicData')({ guid: p.guid })
      },
      newRedPackHotspot() {
        var guid = uuid.v1();
        return {
          addTime: '',
          ath: this.krpano ? this.krpano.get('view.hlookat') : 0,
          atv: this.krpano ? this.krpano.get('view.vlookat') : 0,
          description: "",
          distorted: false,
          file: null,
          gpsLat: 0,
          gpsLng: 0,
          guid: guid,
          icon: {
            guid: null,
            width: 0,
            height: 0,
            fps: 0,
            isAnimation: false,
            edge: 'top',
            imageUrl: '%SWFPATH%/skin/vtourskin_hotspot.png',
            //enableText: true,
            textOffsetX: 0,
            textOffsetY: 0,
            textEdge: 'bottom'
          },
          linkFOV: 120,
          linkHLookAt: 0,
          linkItemGUID: null,
          linkType: 'Pano',
          linkVLookAt: 0,
          points: Array[0],
          rotate: 0,
          scale: 0.5,
          showTitle: true,
          sortID: 2,
          title: "",
          type: 'Image',
          url: null,
          zoom: false,
          ...setting
        }
      },
    },
  }
</script>
<style scoped>
</style>
